import { Icons } from 'common/images';

export const HeaderMenuDatas: HeaderMenuData[] = [
  {
    path: '/about',
    title: '병원 소개',
    sub: [
      {
        path: '/0',
        title: '아라스토리',
      },
      {
        path: '/1',
        title: '병원둘러보기',
      },
      {
        path: '/2',
        title: '의료진소개',
      },
      {
        path: '/3',
        title: '진료시간/오시는길',
      },
    ],
  },

  {
    path: '/pregnant',
    title: '임신/피임',
    sub: [
      {
        path: '/0',
        title: '산전검사',
      },
      {
        path: '/1',
        title: '피임클리닉',
      },
      {
        path: '/2',
        title: '난임클리닉',
      },
    ],
  },

  {
    path: '/disease',
    title: '여성질환',
    sub: [
      {
        path: '/0',
        title: '비정상출혈/생리불순',
      },
      {
        path: '/1',
        title: '월경통/배란통',
      },
      {
        path: '/2',
        title: '월경과다/월경과소',
      },
      {
        path: '/3',
        title: '난소혹(아랫배 통증)',
      },
      {
        path: '/4',
        title: '방광염',
      },
      {
        path: '/5',
        title: '골반염',
      },
      {
        path: '/6',
        title: '다낭성난소증후군',
      },
      {
        path: '/7',
        title: '질염',
      },
      {
        path: '/8',
        title: '자궁경부암',
      },
      {
        path: '/9',
        title: '요실금',
      },
      {
        path: '/10',
        title: '폐경기호르몬요법',
      },
    ],
  },

  // {
  //   path: '/checkup',
  //   title: '여성검진',
  //   sub: [
  //     {
  //       path: '/0',
  //       title: '예비부부검진',
  //     },
  //     {
  //       path: '/1',
  //       title: '자궁/난소검진',
  //     },
  //     {
  //       path: '/2',
  //       title: '갱년기검진',
  //     },
  //   ],
  // },

  {
    path: '/aracare',
    title: '아라케어',
    sub: [
      // {
      //   path: '/0',
      //   title: '영양주사',
      // },
      {
        path: '/1',
        title: '예방접종',
      },
      {
        path: '/2',
        title: '비만클리닉',
      },
      // {
      //   path: '/3',
      //   title: '질필러',
      // },
      // {
      //   path: '/4',
      //   title: '질스케일링',
      // },
    ],
  },

  // {
  //   path: '/inquire',
  //   title: '문의/상담',
  //   // sub: [
  //   //   {
  //   //     path: '/0',
  //   //     title: '진료예약',
  //   //   },
  //   // ],
  // },
];

import PregnantTitleImage0 from 'img/title/pregnant/0.png';
import PregnantTitleImage1 from 'img/title/pregnant/1.png';
import PregnantTitleImage2 from 'img/title/pregnant/2.png';

import DiseaseTitleImage0 from 'img/title/disease/0.png';
import DiseaseTitleImage1 from 'img/title/disease/1.png';
import DiseaseTitleImage2 from 'img/title/disease/2.png';
import DiseaseTitleImage3 from 'img/title/disease/3.png';
import DiseaseTitleImage4 from 'img/title/disease/4.png';
import DiseaseTitleImage5 from 'img/title/disease/5.png';
import DiseaseTitleImage6 from 'img/title/disease/6.png';
import DiseaseTitleImage7 from 'img/title/disease/7.png';
import DiseaseTitleImage8 from 'img/title/disease/8.png';
import DiseaseTitleImage9 from 'img/title/disease/9.png';
import DiseaseTitleImage10 from 'img/title/disease/10.png';

import CheckUPTitleImage0 from 'img/title/checkup/0.png';
import CheckUPTitleImage1 from 'img/title/checkup/1.png';
import CheckUPTitleImage2 from 'img/title/checkup/2.png';

import AraCareTitleImage0 from 'img/title/aracare/0.png';
import AraCareTitleImage1 from 'img/title/aracare/1.png';
import AraCareTitleImage2 from 'img/title/aracare/2.png';
import AraCareTitleImage3 from 'img/title/aracare/3.png';
import AraCareTitleImage4 from 'img/title/aracare/4.png';

export const TitleDatas: TitleData[][] = [
  [],
  [
    {
      headline: '소중한 생명을 맞이하기 위한 단계',
      description: '건강한 임신과 출산을 위해\n아라산부인과가 그여정의 시작을 함께 합니다.',
      image: PregnantTitleImage0,
    },
    {
      headline: '스스로를 지키는 올바른 선택',
      description: '본인에게 적합한 피임법을\n아라산부인과에서 제안합니다.',
      image: PregnantTitleImage1,
    },
    {
      headline: '체계적인 검사와 진단, 맞춤 치료',
      description: '생명을 탄생시키는 기능의 회복뽁만 아니라\n무사 출산까지를 목표로 합니다.',
      image: PregnantTitleImage2,
    },
  ],
  [
    // 비정상출혈/생리불순
    {
      headline: '생리 기간이 아닌데 출혈이 나온다면,',
      description: '몸이 여성에게 보내는 신호,\n놓치지 말고 아라산부인과와 함께 치료하세요.',
      image: DiseaseTitleImage0,
    },
    // 월경통/배란통
    {
      headline: '매달 찾아오는 지긋지긋한 통증,',
      description: '다양한 여성 질환의\n초기증상일 수 있습니다.',
      image: DiseaseTitleImage1,
    },
    // 월경과다/월경과소
    {
      headline: '생리양이 규칙적이지 못하다면',
      description: '늘 일정하지 못한 나만의 그날,\n아라산부인과가 균형을 찾아드립니다.',
      image: DiseaseTitleImage2,
    },
    // 난소혹
    // TODO: 아래 난소혹 내용 나중에 다시 채우기
    {
      headline: '이유 없이 자꾸 배가 아프다면',
      description: '여성 질환의 증상일 수 있습니다.\n조기에 발견해 치료해야 합니다.',
      image: DiseaseTitleImage3,
    },
    // 방광염
    {
      headline: '소변을 참기가 힘들다면',
      description: '재발이 쉬워 정확한 진단,\n치료가 무엇보다 필수입니다.',
      image: DiseaseTitleImage4,
    },
    // 골반염
    {
      headline: '불임의 원인이 되는',
      description: '임신 계획이 있는 여성이라면\n더더욱 주의가 필요합니다.',
      image: DiseaseTitleImage5,
    },
    // 다낭성~~
    {
      headline: '불규칙한 생리주기의 원인',
      description: '모르고 방치하다간\n난임/불임을 초래할 수 있습니다.',
      image: DiseaseTitleImage6,
    },
    // 질염
    {
      headline: '여성에게 흔하게 발생하는 질환',
      description: '건강한 일상으로의 회복을 위해서\n올바른 진단과 치료가 중요합니다.',
      image: DiseaseTitleImage7,
    },
    // 자궁경부암
    {
      headline: '정기 검진이 무엇보다 중요한',
      description: '조기 발견 시 생존율 90% 이상,\n백신으로 예방할 수 있는 유일한 암',
      image: DiseaseTitleImage8,
    },
    // 요실금
    {
      headline: '나도 모르게 소변이 새어 나오는는',
      description: '평안한 일상을 위해선\n빠른 치료가 필수입니다.',
      image: DiseaseTitleImage9,
    },
    // 폐경기호르몬요법
    {
      headline: '여성이면 누구나 피해갈수 없을때',
      description: '성숙한 인생 2막을 시작할 수 있도록\n아라산부인과가 도와드립니다.',
      image: DiseaseTitleImage10,
    },
  ],
  [
    {
      headline: '미리 준비하는 검진',
      description: '결혼 전 건강검진을 통해\n보다 건강하고 행복한 결혼생활을 준비하시기 바랍니다.',
      image: CheckUPTitleImage0,
    },
    {
      headline: '자궁은 신체 건강에 매우 중요한 요소',
      description: '여성들에게 가장 기본적으로 필요한\n자궁/난소 검진입니다.',
      image: CheckUPTitleImage1,
    },
    {
      headline: '자궁은 신체 건강에 매우 중요한 요소',
      description: '결혼 전 건강검진을 통해\n보다 건강하고 행복한 결혼생활을 준비하시기 바랍니다.',
      image: CheckUPTitleImage2,
    },
  ],
  [
    {
      headline: '부족한 영양을 채우는',
      description: '영양소를 직접 주입해\n더욱 즉각적인 효과',
      image: AraCareTitleImage0,
    },
    {
      headline: '질병 예방의 가장 기본',
      description: '밝고 행복한 삶을 위해\n놓치지 말고 미리 준비하세요.',
      image: AraCareTitleImage1,
    },
    {
      headline: '운동 • 식단만으로 관리가 어렵다면',
      description: '군살 없이 건강한 몸매를 위해\n루아산부인과가 함께합니다.',
      image: AraCareTitleImage2,
    },
    {
      headline: '즉각적으로 채워지는 볼륨',
      description: '정교한 주사 시술로\n자연스러운 볼륨감 생성',
      image: AraCareTitleImage3,
    },
    {
      headline: '질 청결 관리 솔루션',
      description: '질 내 환경을 개선해\n각종 여성질환을 예방합니다.',
      image: AraCareTitleImage4,
    },
  ],
];

export const mapCenter = {
  lat: 37.5925927,
  lng: 126.7127742,
};

import InteriorImage0 from 'img/interior/대기실.jpg';
import InteriorImage1 from 'img/interior/대기실2.jpg';
import InteriorImage2 from 'img/interior/진료실.jpg';
import InteriorImage3 from 'img/interior/처치실.jpg';
import InteriorImage4 from 'img/interior/통로.jpg';
import InteriorImage5 from 'img/interior/파우더룸.jpg';
import InteriorImage6 from 'img/interior/파우더룸2.jpg';
import InteriorImage7 from 'img/interior/회복실.jpg';

export const Interiors = [
  {
    image: InteriorImage0,
    name: '대기실',
  },
  {
    image: InteriorImage1,
    name: '대기실2',
  },
  {
    image: InteriorImage2,
    name: '진료실',
  },
  {
    image: InteriorImage3,
    name: '처치실',
  },
  {
    image: InteriorImage4,
    name: '통로',
  },
  {
    image: InteriorImage5,
    name: '파우더룸',
  },
  {
    image: InteriorImage6,
    name: '파우더룸2',
  },
  {
    image: InteriorImage7,
    name: '회복실',
  },
];
